<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-subheader class="body-1 blue--text">{{title}} <v-divider inset></v-divider></v-subheader>
      <v-card-text v-show="!!message" class="body-2 pa-5 black--text" v-html="message" style="background-color:#ffffff"></v-card-text>
      <v-card-actions class="pt-2" style="background-color:#f4f4f4">
        <v-btn v-if="!options.noconfirm" text color="red" outlined class="body-2" @click.native="cancel">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="body-2 font-weight-bold success darken-1" dark text outlined @click.native="agree"><span>OK</span></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ConfirmDlg",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        activityTitle: null,
        message: null,
        title: null,
        options: { color: "", width: 400, zIndex: 200, noconfirm: false },
      }
    },
    methods: {
      open(title, message, activityTitle, options) {
        this.dialog = true
        this.title = title
        this.message = message
        this.activityTitle = activityTitle
        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => { this.resolve = resolve; this.reject = reject; })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      },
    },
  }
</script>